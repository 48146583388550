import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Field } from 'react-final-form'
import { Eye, EyeSlash } from 'styled-icons/fa-regular'
import { Add, Remove } from 'styled-icons/material-rounded'
import NumberFormat from 'react-number-format'
import formatString from 'format-string-by-pattern'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'

const _formatString = (value, mask, pattern) => {
  if (!value || !mask) return value
  let filteredValue = value
  if (pattern) {
    filteredValue = value.replace(pattern, '')
  }
  return formatString(mask, filteredValue)
}

export default function Input({
  label,
  description,
  name,
  autoFocus,
  type,
  className,
  placeholder,
  onChange = () => {},
  showError = undefined,
  showButtons,
  min,
  max,
  mask,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false)
  const numeric = type === 'number'
  const { allowNegative } = props
  const inputRef = useRef()

  const handleOnChange = (e) => {
    const value = Number(e?.target?.value)
    if (!isNaN(value) && onChange) {
      onChange(value)
    }
  }

  return (
    <Field
      name={name}
      parse={(char) => _formatString(char, mask, props.pattern)}
      {...props}
    >
      {({ input, meta }) => {
        const error =
          showError !== undefined && showError instanceof Function
            ? showError({ input, meta })
            : (meta.error || meta.submitError) && meta.touched

        const labelClass = classNames(
          'inline-block font-bold text-medium-grey text-base',
          {
            required: props.required,
            'text-ui-error': error,
            'mb-1': description,
            'mb-2': !description
          }
        )

        const inputClass = classNames(
          'block appearance-none w-full bg-white border border-grey-md hover:border-grey-base px-4 py-3 rounded text-black focus:outline-none focus:border-brand-purple',
          className,
          {
            'border-ui-error hover:border-ui-error': error
          }
        )

        const attributes = {
          ...Object.entries(props)
            .filter(([key]) => key.match(/data-.+/))
            .reduce((acc, entry) => ({ [entry[0]]: entry[1], ...acc }), {})
        }

        return (
          <div className="relative">
            <label htmlFor={name} className={labelClass}>
              {label}
            </label>
            {description && (
              <div className="pb-2 text-grey-">{description}</div>
            )}
            <div className="relative">
              {numeric ? (
                <>
                  {showButtons && (
                    <div
                      className="absolute top-0 left-0 cursor-pointer bg-gp-violet-70"
                      onClick={() => {
                        const _value = Number(props.value)

                        const newVal = _value - 1

                        if (
                          (!allowNegative && _value === 0) ||
                          (!isNaN(min) && newVal < min)
                        )
                          return
                        if (!meta.touched) inputRef.current.focus()
                        input.onChange(newVal)
                        if (onChange) {
                          onChange(newVal)
                        }
                      }}
                    >
                      <Remove
                        className="text-white"
                        style={{ height: '48px', width: '32px' }}
                      />
                    </div>
                  )}

                  <NumberFormat
                    getInputRef={inputRef}
                    id={name}
                    autoFocus={autoFocus}
                    className={inputClass}
                    style={{
                      ...(showButtons && {
                        marginLeft: '30px',
                        width: 'calc(100% - 60px)'
                      })
                    }}
                    placeholder={placeholder || label}
                    onKeyUp={(e) => {
                      input.onChange(e)
                      handleOnChange(e)
                    }}
                    isAllowed={({ floatValue }) => {
                      if (
                        (!isNaN(min) && floatValue < min) ||
                        (!isNaN(max) && floatValue > max)
                      ) {
                        return false
                      }

                      return true
                    }}
                    {...input}
                    {...attributes}
                    {...props}
                    onChange={(e) => {
                      input.onChange(e)
                      handleOnChange(e)
                    }}
                    disabled={props.disabled}
                  />

                  {showButtons && (
                    <div
                      className="absolute top-0 right-0 cursor-pointer bg-gp-violet-70"
                      onClick={() => {
                        const _value = Number(props.value)
                        const newVal = _value + 1
                        if (!isNaN(max) && newVal > max) return
                        if (!meta.touched) inputRef.current.focus()

                        input.onChange(newVal)
                        if (onChange) {
                          onChange(newVal)
                        }
                      }}
                    >
                      <Add
                        className="text-white"
                        style={{ height: '48px', width: '32px' }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <input
                  type={showPassword ? 'text' : type}
                  id={name}
                  autoFocus={autoFocus}
                  className={inputClass}
                  placeholder={placeholder || label}
                  autoComplete={'chrome-off'}
                  onKeyUp={(e) => {
                    input.onChange(e)
                    if (onChange) {
                      const newVal = e?.target?.value
                      onChange(newVal)
                    }
                  }}
                  {...input}
                  {...attributes}
                  onChange={(e) => {
                    input.onChange(e)
                    if (onChange) {
                      const newVal = e?.target?.value
                      onChange(newVal)
                    }
                  }}
                  disabled={props.disabled}
                />
              )}
            </div>

            {props.tooltip && (
              <div
                className="absolute top-0 right-0 w-6 cursor-pointer"
                style={{ margin: '13px', paddingTop: '24px' }}
              >
                <Tooltip
                  placement="right"
                  overlay={<span className="block w-32">{props.tooltip}</span>}
                  arrowContent={<div className="rc-tooltip-arrow-inner" />}
                >
                  <a href="#" className="outline-0">
                    ?
                  </a>
                </Tooltip>
              </div>
            )}

            {props.passwordField && (
              <div
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-0 right-0 w-6 cursor-pointer"
                style={{ margin: '13px', paddingTop: '24px' }}
              >
                {showPassword ? (
                  <Eye className="w-6 h-6 fill-current text-gp-grey-70 hover:text-gp-grey-100 flex-no-shrink" />
                ) : (
                  <EyeSlash className="w-6 h-6 fill-current text-gp-grey-70 hover:text-gp-grey-100 flex-no-shrink" />
                )}
              </div>
            )}
            {props.passwordField && props.passwordStrength && input.value && (
              <PasswordStrengthBar password={input.value} />
            )}

            {!props.hideError && error && (
              <div className="mt-2 text-sm text-ui-error">
                {meta.error || meta.submitError}
              </div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
